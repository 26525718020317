ul.experience{
  font-size: 15px;
      color: #23436a;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
  li {
    a {
      padding: 7px 0;
      display: block;
      
      line-height: 16px;
      color: #ffffff;
    }

    &:hover {
      .anchor-icon {
        color: #ffffff;
      }
    }
  }
}
