$content-width: calc(40vw - 84px);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 40px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #9DBEC8;
$color2: #23436a;

$color3: #9DBEC8;
$color4: #23436a;

$color5: #9DBEC8;
$color6: #23436a;
* {
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
.timeline {
  left:70px;
  top:-100px;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  margin: $margin auto;
  position: relative;
  font-weight:bold;

  &__event {
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    margin: $spacing 0;
    border-radius: $bdrs;
    align-self: center;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
      .timeline__event__date {
        border-radius: 0 $bdrs $bdrs 0;
      }
      .timeline__event__content {
        border-radius: $bdrs 0 0 $bdrs;
      }

      .timeline__event__icon {
        &:before {
          content: "";
          width: 2px;
          height: 100%;
          background: $color2;
          position: absolute;
          top: 0%;
          left: 50%;
          right: auto;
          z-index: -1;
          transform: translateX(-50%);
          animation: fillTop 2s forwards 4s ease-in-out;
        }
        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background: $color2;
          position: absolute;
          right: 0;
          z-index: -1;
          top: 50%;
          left: auto;
          transform: translateY(-50%);
          animation: fillLeft 2s forwards 4s ease-in-out;
        }
      }
    }

    &__title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;

      font-weight: 600;
      color: $color1;
    }
    &__content {
      padding: $spacing;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: $content-width;
      border-radius: 0 $bdrs $bdrs 0;
    }
    &__date {
      color: $color2;
      font-size: 1.5rem;
      font-weight: 600;
      background: $color1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 $spacing;
      border-radius: $bdrs 0 0 $bdrs;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color1;
      padding: $spacing;
      align-self: center;
      margin: 0 $spacing;
      background: $color2;
      border-radius: $bdrs-icon;
      width: $circle-size;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      padding: 40px;
      height: $circle-size;
      position: relative;

      i {
        font-size: $icon-size;
      }

      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background: $color2;
        position: absolute;
        top: 0%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        animation: fillTop 2s forwards 4s ease-in-out;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $color2;
        position: absolute;
        left: 0%;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        animation: fillLeftOdd 2s forwards 4s ease-in-out;
      }
    }
    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:after {
        background: $color4;
      }
      .timeline__event__date {
        color: $color3;
        background: $color4;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color3;
          }
        }
      }

      .timeline__event__icon {
        background: $color3;
        color: $color4;
        &:before,
        &:after {
          background: $color3;
        }
      }
      .timeline__event__title {
        color: $color4;
      }
      .timeline__event__title {
      }
    }

    &--type3 {
      &:after {
        background: $color5;
      }
      .timeline__event__date {
        color: $color6;
        background-color: $color5;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color6;
          }
        }
      }

      .timeline__event__icon {
        background: $color6;
        color: $color5;
        &:before,
        &:after {
          background: $color6;
        }
      }
      .timeline__event__title {
        color: $color5;
      }
      .timeline__event__title {
      }
    }
    &:last-child {
      .timeline__event__icon {
        &:before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .timeline {
    left:0px;
    top: -120px;
    display: flex;
    flex-direction: column;
    margin: $margin auto;
    position: relative;
    overflow-y: hidden;
  }
  //START
  .timeline__event {
    flex-direction: column;
    align-self: center;
    font-size: 25px;
    &__content {
      width: 100%;
    }

    &__icon {
      border-radius: $bdrs $bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
    &__date {
      border-radius: 0;
      padding: $spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline__event__date {
        border-radius: 0;
        padding: $spacing;
      }

      .timeline__event__icon {
        border-radius: $bdrs $bdrs 0 0;
        margin: 0;
      }
    }
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

.stage-cube-cont {
  display:none;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightblue;
  }

  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    width: 50%;
    left:35px;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    top:40%;
    overflow: hidden;
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightblue;
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
}
