.work-in-progress-container {
  z-index: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 30%;
  bottom: 0;
  left: auto;
  margin: auto;

  .solid-work-in-progress {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 50%;
    animation-name: example;
    animation-duration: 4s;
    animation-delay: -2s;
    animation-iteration-count: 2;
    z-index: 1;
    
  }
  &:hover {
    animation: rubberBand 1s;
  }
}

@media screen and (max-width: 1200px) {
  .work-in-progress-container {
    height:100%;
  
    .solid-work-in-progress {
      transform: scale(0.3);
      position:relative;
      //top: 600px;
      bottom: auto;
      left: -370px;
      width: 500%;
      height:100%;
      z-index: 1;
    }
    
  }
}