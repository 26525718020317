.logo-container {
  z-index: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 30%;
  bottom: 0;
  left: auto;
  margin: auto;

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 50%;
    animation-name: example;
    animation-duration: 4s;
    animation-delay: -2s;
    animation-iteration-count: 2;
    z-index: 1;
    
  }
  &:hover {
    animation: rubberBand 1s;
  }
  
  // @keyframes example {
  //   0%   {left:0px; top:0px;}
  //   25%  {left:200px; top:0px;}
  //   50%  {left:200px; top:200px;}
  //   75%  {left:0px; top:200px;}
  //   100% {left:0px; top:0px;}
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(359deg);
  //   }
  // }
}

@media screen and (max-width: 1200px) {
  .logo-container {
    height:100%;
  
    .solid-logo {
      position:relative;
      //top: 600px;
      bottom: auto;
      left: -80px;
      width: 300%;
      height:100%;
      z-index: 1;
    }
    
    // @keyframes example {
    //   0%   {left:0px; top:0px;}
    //   25%  {left:10%; top:10%}
    //   50%  {left:30px; top:30px;}
    //   75%  {left:0px; top:60px;}
    //   100% {left:0px; top:0px;}
    //   from {
    //     transform: rotate(0deg);
    //   }
    //   to {
    //     transform: rotate(359deg);
    //   }
    // }
    // &:hover {
    //   animation: rubberBand 1s;
    // }
  }
}